import { HomeActionTypes, homeInitialState } from "./Model";

const reducer = (state = homeInitialState, action) => {
  switch (action.type) {
    case HomeActionTypes.ASKQUESTION_REQUEST:
      return {
        ...state,
        askLoading: true,
      };
    case HomeActionTypes.ASKQUESTION_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        askLoading: false,
        homeMessage: "Query posted successfully",
        getQueryData:action.payload?.data
      };
    case HomeActionTypes.ASKQUESTION_FAILURE:
      return {
        ...state,
        askLoading: false,
        errorMessage: true,
      };
    case HomeActionTypes.GET_CHAT_HISTORY_SUCCESS:
      var { payload } = action;
      console.log(payload, "hsgdfdfh");
      return {
        ...state,
        history_load: false,
        historyData: payload?.data,
      };
    case HomeActionTypes.GET_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        history_load: false,
      };
    case HomeActionTypes.GET_CHAT_HISTORY_REQUEST:
      return {
        ...state,
        history_load: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export { reducer as HomeReducer };
