import { put, takeEvery, call } from "redux-saga/effects";
import { HomeActionTypes } from "./Model";
import {
  askQusFailure,
  askQusSuccess,
  getchatHistoryFailure,
  getchatHistorySuccess,
} from "./Action";
import { askQues, chathistory } from "./api";

export function* handleAskQus(action) {
  try {
    const response = yield call(askQues, action.payload);
    yield put(askQusSuccess(response));
  } catch (error) {
    yield put(askQusFailure(error));
  }
}

export function* getHistory(action) {
  console.log(action.payload, 3456789)
  try {
    const response = yield call(chathistory, action.payload);
    yield put(getchatHistorySuccess(response));
  } catch (error) {
    yield put(getchatHistoryFailure(error));
  }
}

export function* HomeSaga() {
  yield takeEvery(HomeActionTypes.ASKQUESTION_REQUEST, handleAskQus);
  yield takeEvery(HomeActionTypes.GET_CHAT_HISTORY_REQUEST, getHistory);
}
