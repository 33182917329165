export const HomeActionTypes = {
  ASKQUESTION_REQUEST: "@@home/ASKQUESTION_REQUEST",
  ASKQUESTION_SUCCESS: "@@home/ASKQUESTION_SUCCESS",
  ASKQUESTION_FAILURE: "@@home/ASKQUESTION_FAILURE",

  GET_CHAT_HISTORY_REQUEST: "@@home/GET_CHAT_HISTORY_REQUEST",
  GET_CHAT_HISTORY_SUCCESS: "@@home/GET_CHAT_HISTORY_SUCCESS",
  GET_CHAT_HISTORY_FAILURE: "@@home/GET_CHAT_HISTORY_FAILURE",
};

export const homeInitialState = {
  askLoading: false,
  history_load: false,
  historyData: [],
  homeMessage: "",
  errorMessage: false,
  getQueryData:[]
};
