import { messages } from "../../utils/messages";
import { LawyerActionItems, lawyerInitialState } from "./Model";

const reducer = (state = lawyerInitialState, action) => {
  switch (action.type) {
    case LawyerActionItems.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LawyerActionItems.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        lawyerMessage: action.payload?.data?.message,
      };
    case LawyerActionItems.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: {
          message: action?.payload?.message,
          error: true,
        },
      };

    case LawyerActionItems.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case LawyerActionItems.LOGIN_SUCCESS:
      var { payload } = action;
      if (payload?.data?.role) {
        localStorage.setItem("role", payload?.data?.role);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("id", payload?.data?.data);        
        localStorage.setItem("authToken", payload?.data?.token);
      }
      return {
        ...state,
        loginLoading: false,
        lawyerMessage: action.payload?.data?.message,
      };
    case LawyerActionItems.LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        errorMsg: {
          message: action?.payload?.message,
          error: true,
        },
      };
    /* Post & Get Reducer For  Case Detail */
    case LawyerActionItems.POSTCASEDETAIL_REQUEST:
      return {
        ...state,
        sendcdLoading: true,
      };
    case LawyerActionItems.POSTCASEDETAIL_SUCCESS:
      return {
        ...state,
        sendcdLoading: false,
        lawyerMessage: messages.case_details_updated,
      };
    case LawyerActionItems.POSTCASEDETAIL_FAILURE:
      return {
        ...state,
        sendcdLoading: false,
        errorMsg: {
          message: action?.payload?.message,
          error: true,
        },
      };

    case LawyerActionItems.GETCASEDETAIL_REQUEST:
      var { payload } = action;
      return {
        ...state,
        getcdLoading: true,
      };
    case LawyerActionItems.GETCASEDETAIL_SUCCESS:
      return {
        ...state,
        getcdLoading: false,
        caseDetailData: action.payload?.data,
      };
    case LawyerActionItems.GETCASEDETAIL_FAILURE:
      return {
        ...state,
        getcdLoading: false,
        errorMsg: "Something Went Wrong",
      };
    /* Post& Get Reducer For Setting Detail */
    case LawyerActionItems.POSTSETTINGUPDATEDETAIL_REQUEST:
      return {
        ...state,
        postSetLoading: true,
      };
    case LawyerActionItems.POSTSETTINGUPDATEDETAIL_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        postSetLoading: false,
        lawyerDetails: payload?.data?.data,
        lawyerMessage: "Updated Succesfully",
      };
    case LawyerActionItems.POSTSETTINGUPDATEDETAIL_FAILURE:
      return {
        ...state,
        postSetLoading: false,
        errorMsg: {
          message: action?.payload?.message,
          error: true,
        },
      };
    case LawyerActionItems.CLEAR_LAWYERMESSAGE:
      return {
        ...state,
        lawyerMessage: "",
        errorMsg: {
          error: false,
          message: "",
        },
      };
    case LawyerActionItems.GETLAWYERPROFILEDETAIL_REQUEST:
      return {
        ...state,
        getSetLoading: true,
      };
    case LawyerActionItems.GETLAWYERPROFILEDETAIL_SUCCESS:
      return {
        ...state,
        getSetLoading: false,
        lawyerMessage: messages.get_lawyerDeatils,

        lawyerData: action.payload?.data,
      };
    case LawyerActionItems.GETLAWYERPROFILEDETAIL_FAILURE:
      return {
        ...state,
        getSetLoading: false,
        errorMsg: "Something Went Wrong",
      };

    default:
      return {
        ...state,
      };
  }
};

export { reducer as LawyerReducer };
