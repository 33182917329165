import { messages } from "../../utils/messages";
import { UserActionItems, userrInitialState } from "./Model";

const reducer = (state = userrInitialState, action) => {
  switch (action.type) {
    case UserActionItems.SIGNUP_REQUEST:
      return {
        ...state,
        signupLoading: true,
        isSignedUp: false,
      };
    case UserActionItems.SIGNUP_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        signupLoading: false,
        isSignedUp: true,
        lawyerMessage: payload?.data?.response,
      };
    case UserActionItems.SIGNUP_FAILURE:
      return {
        ...state,
        signupLoading: false,
        isSignedUp: false,
        errorMsg: {
          error: true,
          message: "Signup error",
        },
      };
    case UserActionItems.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case UserActionItems.LOGIN_SUCCESS:
      var { payload } = action;
      if (payload?.data?.role) {
        localStorage.setItem("role", payload?.data?.role);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("user_id", payload?.data?.data);
        localStorage.setItem("authToken", payload?.data?.token);
      }
      return {
        ...state,
        loginLoading: false,
        loginResponse: payload?.data?.message,
      };
    case UserActionItems.LOGIN_FAILURE:
      var { payload } = action;
      return {
        ...state,
        loginLoading: false,
        errorMsg: {
          message: payload?.message,
          error: true,
        },
      };
    case UserActionItems.UPDATE_USERPROFILE_REQUEST:
      var { payload } = action;
      return {
        ...state,
      };
    case UserActionItems.UPDATE_USERPROFILE_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        userMessage: messages.userDetails_updated,
      };
    case UserActionItems.UPDATE_USERPROFILE_FAILURE:
      var { payload } = action;
      return {
        ...state,
        errorMsg: { message: messages.userUpdateFail, error: true },
      };
    case UserActionItems.USERPROFILE_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case UserActionItems.USERPROFILE_SUCCESS:
      var { payload } = action;
      console.log(payload, "auhsvduahsvd");
      return {
        ...state,
        loginLoading: false,
        userData: payload?.data,
        userMessage: messages.get_userDetails,
      };
    case UserActionItems.USERPROFILE_FAILURE:
      return {
        ...state,
        loginLoading: false,
        errorMsg: { message: messages.getuserDetailFailed, error: true },
      };
    case UserActionItems.CLEAR_FLAGS:
      return {
        ...state,
        isSignedUp: false,
        errorMsg: {
          error: false,
          message: "",
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export { reducer as userReducer };
