import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Skeleton } from "@mui/material";

const Chat = ({ message, isStop }) => {
  console.log(message, "56789")
  return (
    <section className="bd">
      <div className="scroll">
        <div className="ai-chat">
          <div>
            <FontAwesomeIcon className="ai-icon" icon={faRobot} />
          </div>
          <div className="ai-text-info">
            <p>Hello! How Can I help you?</p>
          </div>
        </div>
        {message&&message.map((msg, idx) => (
          <div key={idx}>
            <div className="user-chat">
              <div className="user-text-info">
                <p>{msg.question}</p>
              </div>
              <div>
                <FontAwesomeIcon className="user-icon" icon={faUser} />
              </div>
            </div>
            {isStop && idx == message.length - 1 && (
              <>
                <div className="ai-chat">
                  <div>
                    <Skeleton
                      variant="circular"
                      animation={"wave"}
                      sx={{
                        fontSize: "0.5 rem",
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                  <div className="ai-text-info">
                    <p>
                      <b>
                        {" "}
                        <Skeleton
                          variant="text"
                          animation={"wave"}
                          sx={{ fontSize: "1rem", width: "150px" }}
                        />
                      </b>{" "}
                      <Skeleton
                        variant="text"
                        animation={"wave"}
                        sx={{ fontSize: "1rem", width: "200px" }}
                      />
                    </p>
                    <p>
                      <b>
                        {" "}
                        <Skeleton
                          variant="text"
                          animation={"wave"}
                          sx={{ fontSize: "1rem", width: "150px" }}
                        />
                      </b>{" "}
                      <Skeleton
                        variant="text"
                        animation={"wave"}
                        sx={{ fontSize: "1rem", width: "50px" }}
                      />
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="ai-chat">
              <div className="ai-text-info">
                {isStop && idx == message.length - 1 ? (
                  <></>
                ) : (
                  <>
                    <div>
                      <FontAwesomeIcon className="ai-icon" icon={faRobot} />
                    </div>
                    {Object.entries(msg).map(
                      ([key, value]) =>
                        key !== "question" && (
                          <p key={key}>
                            <b>{key}:</b> {value}
                          </p>
                        )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Chat;
