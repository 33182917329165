import { Alert, IconButton, Snackbar, Tab, TextField } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidator } from "./Validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clearLawyerMessage,
  getLawyerProfileRequest,
  postSettingDetailRequest,
} from "./Action";
import { lawyerSelector } from "./Selector";
import { useDispatch, useSelector } from "react-redux";
import { faCamera, faUser } from "@fortawesome/free-solid-svg-icons";
import profile from "../../assets/image/profile.jpg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { messages } from "../../utils/messages";
const LawyerSettings = () => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("id");

  const [value, setValue] = useState("1");
  const { lawyerMessage, lawyerData, errorMsg } = useSelector(lawyerSelector);
  console.log(lawyerData, "2345");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(profileValidator),
  });

  console.log(errors, "errors");

  const handleClose = () => {
    dispatch(clearLawyerMessage());
  };
  useEffect(() => {
    if (lawyerMessage == messages.get_lawyerDeatils) reset(lawyerData);
    dispatch(clearLawyerMessage());
  }, [lawyerData, lawyerMessage]);

  useEffect(() => {
    dispatch(getLawyerProfileRequest(id));
  }, [id]);

  const onSubmitHandler = (formData) => {
    delete formData.updated;
    delete formData.password;
    delete formData.created;
    dispatch(postSettingDetailRequest({ formData }));
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );

  console.log(errors, "bar_no");

  return (
    <div>
      <section>
        <Container>
          <Snackbar
            open={lawyerMessage == "Updated Succesfully"}
            autoHideDuration={5000}
            onClose={handleClose}
            message={lawyerMessage}
            action={action}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {lawyerMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorMsg?.error}
            autoHideDuration={5000}
            onClose={handleClose}
            message={errorMsg?.message}
            action={action}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMsg?.message}
            </Alert>
          </Snackbar>
          <Row className="profile">
            <Col md={4}>
              <div className="card-body-style image">
                <Col className="profile-col" md={4}>
                  <div className="profile-bg">
                    <FontAwesomeIcon className="circle" icon={faUser} />
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={8}>
              <div className="card-body-style">
                <h3 className="profile-title">Profile</h3>
                <Form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className="profile-form"
                >
                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Name"}
                          {...register("name")}
                        />
                        {errors?.name && (
                          <div>
                            <p className="text-danger">
                              {errors?.name?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="color w-100 m-1"
                          variant="outlined"
                          label={"Email"}
                          disabled
                          {...register("email")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="color w-100 m-1"
                          variant="outlined"
                          label={"Phone Number"}
                          disabled
                          {...register("mobile_no")}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Bar Number"}
                          {...register("bar_no")}
                        />
                        {errors?.bar_no && (
                          <div>
                            <p className="text-danger">
                              {errors?.bar_no?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Enroll Number"}
                          {...register("enroll_no")}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"State"}
                          {...register("state")}
                        />
                        {errors?.state && (
                          <div>
                            <p className="text-danger">
                              {errors?.state?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"District"}
                          {...register("district")}
                        />
                        {errors?.district && (
                          <div>
                            <p className="text-danger">
                              {errors?.district?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"No of cases"}
                          {...register("cases_total")}
                        />
                        {errors?.cases_total && (
                          <div>
                            <p className="text-danger">
                              {errors?.cases_total?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Won cases"}
                          {...register("cases_won")}
                        />
                        {errors?.cases_won && (
                          <div>
                            <p className="text-danger">
                              {errors?.cases_won?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Loss cases"}
                          {...register("cases_loss")}
                        />
                        {errors?.cases_loss && (
                          <div>
                            <p className="text-danger">
                              {errors?.cases_loss?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Number of Criminal Cases"}
                          {...register("criminal_cases")}
                        />
                        {errors?.criminal_cases && (
                          <div>
                            <p className="text-danger">
                              {errors?.criminal_cases?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Number of Civil Cases"}
                          {...register("civil_cases")}
                        />
                        {errors?.civil_cases && (
                          <div>
                            <p className="text-danger">
                              {errors?.civil_cases?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="text-end">
                    <Button className="update" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default LawyerSettings;
