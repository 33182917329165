import { io } from "socket.io-client";

const URL = "http://192.168.0.103:5001";
const origin = "http://localhost:3000";
// process.env.REACT_APP_NODE_ENV == "development"
//   ? process.env.REACT_APP_NODELOCALASSETURL
//   : process.env.REACT_APP_NODEUATASSETURL;

export const socket = io(URL, {
  transports: ["websocket"],
  cors: {
    origin,
  },
});

// export const socket = io(URL);
