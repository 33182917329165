import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { AiOutlineSend } from "react-icons/ai";
import Chat from "../Chat/Chat";
import { useEffect, useState } from "react";
import Default from "../Default";
import SideBar from "../../layout/SideBar";
import NavLayout from "../../layout/Nav";
import { useDispatch, useSelector } from "react-redux";
import { askQusRequest, getchatHistoryRequest } from "./Action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import { homeSelector } from "./Selector";
import { Skeleton } from "@mui/material";
import { socket } from "../../services/socket";
import { v4 as uuidv4 } from "uuid";

const Home = () => {
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuthenticated");
  const user_id = localStorage.getItem("user_id");
  const uid = localStorage.getItem("uid");
  const { history_load, historyData, homeMessage, errorMessage, getQueryData } =
    useSelector(homeSelector);
  console.log(historyData, 67890);
  const [query, setQuery] = useState("");
  const [socketId, setSocketId] = useState();
  console.log(socketId, "socketId id");
  const [messages, setMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  useEffect(() => {
    if (!isAuth && !uid) {
      localStorage.setItem("uid", uuidv4());
    } else if (user_id) {
      localStorage.removeItem("uid");
    }
  }, [isAuth]);

  useEffect(() => {
    if (getQueryData?.description) {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        const lastMessage = newMessages[newMessages.length - 1];
        // const key = Object.keys(getQueryData)[0];
        // if (lastMessage) {
          lastMessage["description"] = getQueryData?.description;
        // }  
        return newMessages;
      });
      setLoading(false);
    }
  }, [getQueryData]);

  useEffect(() => {
    dispatch(getchatHistoryRequest({ id: user_id, query: "2" }));
    // socket.on("data", (data) => {
    //   // if (data.user_id === user_id || data.user_id == uid) {
    //   console.log(data, socketId, data?.socket_id, "data socket data");
    //   if (socketId == data?.socket_id) {
    //     setMessages((prevMessages) => {
    //       const newMessages = [...prevMessages];
    //       const lastMessage = newMessages[newMessages.length - 1];
    //       const key = Object.keys(data)[0];
    //       if (lastMessage) {
    //         lastMessage[key] = data[key];
    //       }
    //       return newMessages;
    //     });
    //     setLoading(false);
    //     dispatch(getchatHistoryRequest({ id: user_id, query: "2" }));
    //   }
    // });

    // return () => {
    //   socket.off("data");
    // };
  }, [dispatch, user_id, socketId]);

  const handleSubmit = () => {
    if (!query.trim()) return;
    console.log(socket?.id, "socket id");
    const formData = {
      query,
      user_id,
      socket_id: socket?.id,
      uid,
    };

    setMessages([...messages, { question: query }]);
    dispatch(askQusRequest(formData));
    setLoading(true);
    console.log(user_id, "emit user_id");
    // setTimeout(() => {
    //   socket.emit("data", { user_id: user_id ? user_id : uid, query });
    // }, 1500);
    console.log(socket, socket?.id, "socket sid");
    setSocketId(socket?.id);
    setQuery("");
  };

  return (
    <Row className="h-100">
      <Col>
        {!isAuth && <NavLayout />}
        <div className="chat-container">
          <div className="chat-part">
            <Row className="chat-screen">
              <Col md={3}></Col>
              <Col md={6}>
                <Chat message={messages} isStop={isLoading} />
              </Col>
              {isAuth && (
                <Col className="bg-width position" md={3}>
                  <div className="bg">
                    <div className="history">
                      <ul>
                        {history_load &&
                          [1, 2]?.map((data, idx) => (
                            <React.Fragment key={idx}>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "50%" }}
                                animation="wave"
                              />
                              <hr />
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem" }}
                                animation="wave"
                              />
                            </React.Fragment>
                          ))}
                        {historyData?.total_count > 0 &&
                          historyData?.history_data?.map((history, index) => (
                            <div key={index} className="history-content">
                              {/* <span>Today</span>
                            <hr /> */}
                              <li>
                                <FontAwesomeIcon
                                  className="law"
                                  icon={faGavel}
                                />
                                {history?.query}
                              </li>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <div className="d-flex justify-content-center">
              <div className="privacy">
                <ul>
                  <li>Privacy</li>
                  <li>Terms</li>
                  <li>Policy</li>
                </ul>
              </div>
              <div className="chat-text-box text-center">
                <div className="d-flex align-items-center">
                  <div className="newchat">
                    <IoChatbubbleEllipsesOutline
                      className="message"
                      size={30}
                    />
                  </div>
                  <div className="w-100 d-flex align-items-center p-2 rounded-3 mx-2">
                    <textarea
                      name="chat"
                      rows={2}
                      value={query}
                      className="w-100 rounded-3 mx-3 p-2 border-0 chat-bar"
                      placeholder="Type message ..."
                      onChange={handleQuery}
                    ></textarea>
                    <button
                      className="send pointer-cursor d-flex justify-content-center align-items-center bg-light"
                      onClick={handleSubmit}
                    >
                      <AiOutlineSend />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Home;
