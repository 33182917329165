import { Http } from "../../services/Https";
import { getBaseEndpointUrl } from "../../services/config";

export const askQues = (data) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "query", data);
};

export const chathistory = ({ id, query }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "chat_history/" + id + "?query=" + query,
    Http.getAuthToken()
  );
};
